import axios from 'axios'
import { getAuthHeader } from './config'
import { API_URL } from '../constants/api'

const getDeadLines = async () => {
  const response = await axios.get(`${API_URL}/deadlines`, getAuthHeader())
  return response.data
}

const getDeadLinesCalendar = async () => {
  const response = await axios.get(`${API_URL}/deadlines/calendar`, getAuthHeader())
  return response.data
}

const deadlinesService = {
  getDeadLines,
  getDeadLinesCalendar
}
export default deadlinesService
