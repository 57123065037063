import axios from 'axios'
import { getAuthHeader } from './config'
import { API_URL } from '../constants/api'

const moduleRoute = '/assessments'
const baseURL = API_URL + moduleRoute

const getAllExams = async (courseId) => {
  const response = await axios.get(`${API_URL}/${courseId}${moduleRoute}`, {
    ...getAuthHeader(),
    params: { filter: 'Exam' }
  })
  return response.data
}

const getAllAssignments = async (courseId) => {
  const response = await axios.get(`${API_URL}/${courseId}${moduleRoute}`, {
    ...getAuthHeader(),
    params: { filter: 'Assignment' }
  })
  return response.data
}

const submitAssessment = async (courseId, assessment) => {
  const response = await axios.post(
    `${API_URL}/${courseId}${moduleRoute}`,
    assessment,
    getAuthHeader()
  )
  return response.data
}

const deleteAssessment = async (courseId, assessmentId) => {
  const response = await axios.delete(
    `${API_URL}/${courseId}${moduleRoute}/${assessmentId}`,
    getAuthHeader()
  )
  return response.data
}

const assessmentsService = {
  getAllExams,
  getAllAssignments,
  submitAssessment,
  deleteAssessment
}
export default assessmentsService
