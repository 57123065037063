import React from 'react'
import styled from 'styled-components'
import logo from '../assets/logo.jpg'

const LogoContainer = styled.div`
  height: 28px;
  margin: 16px;
`

const LogoContainer2 = styled.div`
  height: 28px;
  margin: 16px 8px;
`

const Logo = ({ collapsed }) => {
  if (collapsed)
    return (
      <LogoContainer2>
        <img src={logo} style={{ height: 30, width: 60}} />
        {/* <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 306.2 155.26"
        >
          <text
            className="logo2-cls-3"
            transform="translate(55.82 50.47) scale(1.24 1)"
          >
            Hogar
          </text>
          <text
            className="logo2-cls-3"
            transform="translate(70.82 122.47) scale(1.24 1)"
          >
            TMS
          </text>
        </svg> */}
      </LogoContainer2>
    )
  return (
    <LogoContainer>
      <img src={logo} style={{ height: 45, width: 110}} />
      {/* <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 856.31 203.56"
      >
      <text className="logo1-cls-4" transform="translate(20.2 139.42)">
          Hogar
        </text>
        <text className="logo1-cls-4" transform="translate(377.2 139.42)">
          T M S
        </text>
       
      </svg> */}
    </LogoContainer>
  )
}

export default Logo
