import axios from 'axios'
import { getAuthHeader } from './config'
import { API_URL } from '../constants/api'

export const baseURL = API_URL + '/achievements'

const getAllAchievements = async () => {
  const response = await axios.get(`${baseURL}`, getAuthHeader())
  return response.data
}

const achievementsService = {
  getAllAchievements
}
export default achievementsService
